@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* box shadow for navigation */
.box-shadow-navigation {
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
}

/* stepper */
.stepper {
    counter-reset: step;
}

.stepper li {
    list-style-type: none;
    float: left;
    width: 64px;
    position: relative;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .stepper li {
        width: 42px;
    }
}

@media screen and (min-width: 820px) {
    .stepper li {
        width: 46px;
    }
}

@media screen and (min-width: 912px) {
    .stepper li {
        width: 50px;
    }
}

@media screen and (min-width: 1024px) {
    .stepper li {
        width: 58px;
    }
}

/* Media query for screen width greater than or equal to 1024px */
@media screen and (min-width: 1440px) {
    .stepper li {
        width: 64px;
    }
}

.stepper li:before {
    content: counter(step);
    counter-increment: step;
    font-size: 16px;
    line-height: 22.4px;
    align-items: center;
    border-radius: 20px;
    width: 24px;
    height: 24px;
    display: block;
    margin: 0 auto 10px;
    background: #99abc9;
    color: white;
}

.stepper li:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #99abc9;
    top: 10px;
    left: -50%;
    z-index: -1;
}

.stepper li:first-child:after {
    content: none;
}

.stepper li.complete {
    color: #016fb9;
}

.stepper li.complete:before {
    background-color: #016fb9;
    color: white;
}

.stepper li.complete + li:after {
    background-color: #016fb9;
}

.stepper li.active:before {
    background-color: #016fb9;
    color: white;
    box-shadow:
        0 0 0 2px white,
        0 0 0 4px #016fb9;
}

/* segmented control for commercial industrial */

.segmented-controls {
    display: flex;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    border-radius: 40px;
    background: white;
    padding: 2px;
    border: 1px solid #016fb9;
    width: 242px;
    height: 41px;
}

.segmented-controls input {
    display: none;
}

.segmented-controls label {
    grid-row: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    flex: 1 1 0px;
    position: relative;
    z-index: 2;
    transition: inherit;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: #016fb9;
}

.segmented-controls label:last-of-type {
    z-index: 1;
}

.segmented-controls label:last-of-type::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -2;
    transition: inherit;
    background: #016fb9;
    border-radius: 40px;
}

.segmented-controls input:checked + label {
    color: #ffffff;
}

.segmented-controls input:nth-last-of-type(2):checked ~ label:last-of-type::after {
    transform: translateX(-100%);
}

/* scrollbar for video tutorial */
.scrollbar-video-tutorial::-webkit-scrollbar {
    width: 4px;
}

.scrollbar-video-tutorial::-webkit-scrollbar-thumb {
    background-color: #016fb9;
    border-radius: 4px;
}

/* circle marker for measurement page */
.circle-marker {
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #fdc921;
    border: 1px solid #fdc921;
    top: 0px;
    left: 0px;
    transform: translate(-50%, -50%);
}

/* put out the arrow of the input number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
